import DefaultLayout from 'layout/Default/DefaultLayout';
import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import appRoutes from 'routes/routes';

export default function App() {
    return (
        <div className="rootPage">
            <Routes>
                {appRoutes.map((route, index) => {
                    const Page = route.page;
                    let Layout: any;
                    if (!route.layout) {
                        Layout = DefaultLayout;
                    } else {
                        Layout = Fragment;
                    }
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            element={
                                <Layout>
                                    <Page />
                                </Layout>
                            }
                        />
                    );
                })}
            </Routes>
        </div>
    );
}
