import { useTranslation } from 'react-i18next';
import './Footer.scss';
export default function Footer() {
    const { t } = useTranslation();
    return (
        <div className="footer">
            <div className="footerContent">
                {/* left */}
                <div className="footerLeft">
                    <div className="logoApp">
                        <img className="logoFooter" src={require('assets/images/logos/logo.png')} alt="" />
                        <div className="appDownload">
                            <button type="button">
                                <img src={require('assets/images/ios.png')} alt="" />
                            </button>
                            <button type="button">
                                <img src={require('assets/images/android.png')} alt="" />
                            </button>
                        </div>
                    </div>
                    <div className="pagcorLicense">
                        <img src={require('assets/images/pagcor.png')} alt="" />
                        <p>
                            This website has received <br /> a license from PAGCOR
                        </p>
                    </div>
                </div>

                {/* center */}
                <div className="footerCenter">
                    <p>{t('partner')}</p>
                    <div className="partnerList">
                        <div>
                            <img src={require('assets/images/ag-gaming.png')} alt="" />
                        </div>
                        <div>
                            <img src={require('assets/images/instant-lottery.png')} alt="" />
                        </div>
                        <div>
                            <img src={require('assets/images/ebet.png')} alt="" />
                        </div>
                        <div>
                            <img src={require('assets/images/playtech.png')} alt="" />
                        </div>
                        <div>
                            <img src={require('assets/images/ibc.png')} alt="" />
                        </div>
                        <div>
                            <img src={require('assets/images/bbin.png')} alt="" />
                        </div>
                    </div>
                </div>

                <div className="footerRight">
                    <ul className="pageList">
                        <li className="pageItem">{t('all')}</li>
                        <li className="pageItem">{t('vip')}</li>
                        <li className="pageItem">{t('asian')}</li>
                        <li className="pageItem">{t('northern')}</li>
                        <li className="pageItem">{t('southern')}</li>
                        <li className="pageItem">{t('central')}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
