import { ViewTypeItem } from 'models/chanelModel';
import { LanguageItem } from 'models/languageModel';
import { MenuChanel } from 'models/menuModel';

export const menuHeader: MenuChanel[] = [
    {
        name: 'vietnam',
        icon: require('assets/images/flag/vietnam.png'),
        subMenu: [
            {
                name: 'all',
                value: -1,
            },
            {
                name: 'vip',
                value: 0,
            },
            {
                name: 'asian 24h',
                value: 1,
            },
            {
                name: 'southern',
                value: 4,
            },
            {
                name: 'central',
                value: 3,
            },
            {
                name: 'northern',
                value: 2,
            },
        ],
    },

    {
        name: 'china',
        icon: require('assets/images/flag/china.png'),
        subMenu: [
            {
                name: 'all',
                value: -1,
            },
            {
                name: 'vip',
                value: 0,
            },
        ],
    },
    {
        name: 'thailand',
        icon: require('assets/images/flag/thailan.png'),
        subMenu: [
            {
                name: 'all',
                value: -1,
            },
            {
                name: 'vip',
                value: 0,
            },
        ],
    },
    {
        name: 'lao',
        icon: require('assets/images/flag/lao.png'),
        subMenu: [
            {
                name: 'all',
                value: -1,
            },
            {
                name: 'vip',
                value: 0,
            },
        ],
    },
    {
        name: 'singapore',
        icon: require('assets/images/flag/singapore.png'),
        subMenu: [
            {
                name: 'all',
                value: -1,
            },
            {
                name: 'vip',
                value: 0,
            },
        ],
    },
    {
        name: 'malaysia',
        icon: require('assets/images/flag/malaysia.png'),
        subMenu: [
            {
                name: 'all',
                value: -1,
            },
            {
                name: 'vip',
                value: 0,
            },
        ],
    },
    {
        name: 'philippines',
        icon: require('assets/images/flag/philippine.png'),
        subMenu: [
            {
                name: 'all',
                value: -1,
            },
            {
                name: 'VIP',
                value: 0,
            },
        ],
    },
];

export const languageList: LanguageItem[] = [
    {
        name: 'English',
        value: 'en',
        image: require('assets/images/flag/uk.png'),
    },
    {
        name: 'Tiếng Việt',
        value: 'vi',
        image: require('assets/images/flag/vietnam.png'),
    },
    {
        name: 'China',
        value: 'cn',
        image: require('assets/images/flag/china.png'),
    },
];

export const listViewType: ViewTypeItem[] = [
    {
        view: 'NORMAL',
        text: 'normal',
    },
    {
        view: '2NUMBER',
        text: '2number',
    },
    {
        view: '3NUMBER',
        text: '3number',
    },
];
