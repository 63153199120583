/* eslint-disable */

import userApi from 'apis/userApi';
import classNames from 'classnames';
import TableResult from 'components/TableResult/TableResult';
import TimeCount from 'components/TimeCount/TimeCount';
import { VIEWTYPE } from 'models/chanelModel';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { listViewType } from 'utils/menu';
import { getTime } from 'utils/timeConvert';
import './LotteryChanel.scss';

interface Props {
    historyList: any[];
    curentIssueProps: any;
    onViewDetail?: (lotteryid: number) => void;
    orderHistory?: number;
    detail?: boolean;
}

let idInterval: any = null;

function LotteryChanel(props: Props) {
    const { historyList, curentIssueProps, onViewDetail, orderHistory, detail } = props;
    const { t } = useTranslation();

    const [viewType, setViewType] = useState<VIEWTYPE>('NORMAL');

    const [issueHistoryList, setIssueHistoryList] = useState<any[]>(historyList);
    const [issueLotterySelect, setIssueLotterySelect] = useState<any>(historyList[0]);

    const [currentIssue, setCurrentIssue] = useState<any>(curentIssueProps);

    useEffect(() => {
        checkOpenWin();
        return () => {
            if (idInterval) clearInterval(idInterval);
        };
    }, []);

    const handleChangeView = (type: VIEWTYPE) => {
        setViewType(type);
    };

    const checkOpenWin = () => {
        const currentTime = new Date().getTime();
        const closeWinTime = new Date(currentIssue.hisopentime).getTime();
        const openWinTime = new Date(currentIssue.hissysopentime).getTime();
        if (openWinTime < currentTime && currentTime <= closeWinTime) {
            handleLotteryDetail(currentIssue.lotteryid);
        }
    };

    const handleChangeIssue = (issue: string) => {
        if (idInterval) clearInterval(idInterval);
        const issueMatch = issueHistoryList.find((item) => item.issue == issue);
        if (issueMatch) {
            if (issueMatch == issueHistoryList[0]) {
                checkOpenWin();
            }
            setIssueLotterySelect(issueMatch);
        }
    };

    const handleLotteryDetail = (lotteryId: number) => {
        const payload = new URLSearchParams({
            flag: 'LotteryListBean',
            lotteryId: `${lotteryId}`,
            num: `${orderHistory ? orderHistory : 7}`,
        });

        // lấy kết quả lần đầu tiên khi hết giờ cược
        getLotteryDetail(payload);

        if (idInterval) clearInterval(idInterval);
        idInterval = setInterval(async () => {
            //kiểm tra thời gian lấy kết quả còn hiệu lực
            if (new Date(currentIssue.opentime).getTime() <= new Date().getTime()) {
                clearInterval(idInterval);
            }
            getLotteryDetail(payload);
        }, 10000);
    };

    const getLotteryDetail = async (payload: any) => {
        try {
            const { data, status } = await userApi.getListBeanDetail(payload);
            if (status === 200) {
                const lotteryBean = data.data[0];
                if (lotteryBean) {
                    setCurrentIssue(lotteryBean.cursitem);
                    updateListIssue(lotteryBean.history);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateListIssue = (historyList: any[]) => {
        setIssueHistoryList(historyList);
        setIssueLotterySelect(historyList[0]);
    };

    const handleViewDetailChanel = () => {
        if (onViewDetail) {
            onViewDetail(currentIssue.lotteryid);
        }
    };

    return (
        <div className={classNames('lotteryContainer', { detail })}>
            <div className="resultTable">
                <div className="resultTitle">
                    {issueLotterySelect &&
                        listViewType.map((item) => (
                            <div className="viewOptionItem" key={item.view}>
                                <input
                                    type="radio"
                                    name={`${issueLotterySelect.issue}_${issueLotterySelect.lotteryid}`}
                                    id={`${item.view}_${issueLotterySelect.lotteryid}`}
                                    defaultChecked={item.view === viewType}
                                    onChange={() => handleChangeView(item.view)}
                                />
                                <label htmlFor={`${item.view}_${issueLotterySelect.lotteryid}`}>{t(`${item.text}`)}</label>
                            </div>
                        ))}
                </div>
                {issueLotterySelect && (
                    <TableResult
                        tableDataProps={issueLotterySelect.jsonWinNbr}
                        viewType={viewType}
                        nextIdxOpenWin={issueLotterySelect.nextIndex}
                    />
                )}
            </div>
            <div className="sectionResultList">
                <div className="resultTitle">
                    <p>{t('issue')}</p>
                </div>

                {/* issue list desktop */}
                <div className="issueList">
                    {issueHistoryList.map((item) => (
                        <p
                            className={classNames('issueItem', { active: item.issue == issueLotterySelect.issue })}
                            key={item.issue}
                            onClick={() => handleChangeIssue(item.issue)}
                        >
                            {item.issue}
                        </p>
                    ))}
                </div>
            </div>
            <div className="nextIssueWrapper">
                <div className="resultTitle">
                    <p>{t('nextissue')}</p>
                </div>
                <div className="nextIssueContent">
                    <p className="titleNextIssueMobile">{t('nextissue')}</p>
                    <div className="issueId">
                        <p>{t('issue')}:</p>
                        <span>{currentIssue.issue}</span>
                    </div>
                    <div className="issueDate">
                        <p>{t('date')}:</p>
                        <span>{moment(currentIssue.opentime).format('DD-MM-YYYY')}</span>
                    </div>
                    <div className="issueTimeline">
                        <p>{t('time')}:</p>
                        <span>
                            {getTime(currentIssue.hissysopentime)} - {getTime(currentIssue.sysopentime)}
                        </span>
                    </div>
                    <div className="issueTime">
                        <p className="issueTimeDesc">{t('timecount')}</p>
                        <p className="issueTimeCount">
                            {issueLotterySelect && (
                                <TimeCount
                                    time={currentIssue.sysopentime}
                                    onTimeOut={() => handleLotteryDetail(issueLotterySelect.lotteryid)}
                                />
                            )}
                        </p>
                    </div>
                    {onViewDetail && (
                        <button type="button" className="btnDetail" onClick={handleViewDetailChanel}>
                            {t('detail')}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default LotteryChanel;
