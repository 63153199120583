import { languageList, menuHeader } from 'utils/menu';
import { IoChevronDown } from 'react-icons/io5';
import { BsCheck } from 'react-icons/bs';
import { HiMenu } from 'react-icons/hi';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageItem } from 'models/languageModel';
import './Header.scss';
import { MenuChanel } from 'models/menuModel';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface Props {
    onChangeRoute: (routeValue: MenuChanel) => void;
    countrySelected: string;
    language: LanguageItem;
    onChangeLanguage: (lang: LanguageItem) => void;
    onShowMenuMobile: () => void;
}

export default function Header(props: Props) {
    const { onChangeRoute, countrySelected, language, onChangeLanguage, onShowMenuMobile } = props;

    const { t } = useTranslation();
    const languageRef = useRef<HTMLDivElement>(null);

    function handleClickOutside(event: any) {
        if (languageRef.current && !languageRef.current.contains(event.target)) {
            languageRef.current.classList.remove('open');
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [languageRef]);

    const onOpenLanguage = () => {
        if (languageRef.current) languageRef.current.classList.add('open');
    };

    const handleChangeCountry = (item: MenuChanel) => {
        onChangeRoute(item);
    };

    const handleChangeLanguage = (item: LanguageItem) => {
        if (languageRef.current) {
            languageRef.current.classList.remove('open');
        }
        onChangeLanguage(item);
    };
    return (
        <div className="headerWrapper">
            <div className="headerContent">
                <button type="button" className="btn-menu-mobile" onClick={onShowMenuMobile}>
                    <HiMenu size={36} />
                </button>
                {/* logo */}
                <Link className="logo" to="/">
                    <img src={require('assets/images/logos/logo.png')} alt="" />
                </Link>

                {/* menu list */}
                <ul className="menuList">
                    {menuHeader.map((menu, index) => (
                        <li className="menuItem" key={index}>
                            <p
                                className={classNames('countryName', { active: menu.name === countrySelected })}
                                onClick={() => handleChangeCountry(menu)}
                            >
                                {t(`${menu.name}`)}
                            </p>
                        </li>
                    ))}
                </ul>

                {/* language */}
                <div className="language">
                    <button type="button" className="btnLanguage" onClick={onOpenLanguage}>
                        <img src={language.image} alt="" />
                        <p>{language.name}</p>
                        <IoChevronDown size={18} />
                    </button>

                    {/* list language option */}
                    <div className="optionLanguage" ref={languageRef}>
                        <ul className="languageList">
                            {languageList.map((item, index) => (
                                <li key={index} className="languageItem" onClick={() => handleChangeLanguage(item)}>
                                    <BsCheck size={24} className={item.value === language.value ? 'active' : ''} />
                                    <img src={item.image} alt="" />
                                    <span>{item.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* //list language option */}
                </div>
            </div>
        </div>
    );
}
