import { AxiosResponse } from 'axios';
import axiosClient from './axiosInstance';

const userApi = {
    getListLottery(payload: any): Promise<AxiosResponse<any>> {
        const url = 'UserService.aspx';
        return axiosClient.post(url, payload);
    },
    getListBeanDetail(payload: any): Promise<AxiosResponse<any>> {
        const url = 'UserService.aspx';
        return axiosClient.post(url, payload);
    },
};

export default userApi;
