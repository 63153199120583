/* eslint-disable */
import classNames from 'classnames';
import NumberRun from 'components/NumberRun/NumberRun';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './TableResult.scss';

interface KQItem {
    value: string;
    idx: number;
}
interface Props {
    tableDataProps: any[];
    viewType: 'NORMAL' | '2NUMBER' | '3NUMBER';
    nextIdxOpenWin: number;
}

function genrateArrayNumber(length: number) {
    let listNumber: number[] = [];
    for (let i = 0; i < length; i++) {
        listNumber.push(Math.floor(Math.random() * (9 - 0 + 1) + 0));
    }

    return listNumber;
}

function TableResult(props: Props) {
    const { tableDataProps, viewType, nextIdxOpenWin } = props;

    const { t } = useTranslation();

    const [tableData, setTableData] = useState<any[]>([]);

    useEffect(() => {
        let dataLotteryList: any[] = [];
        let indexValue = 1;
        for (const key in tableDataProps) {
            if (tableDataProps[key] != '') {
                // convert string to string array
                const values: any[] = tableDataProps[key].split(' - ');

                //add index every kq
                let kqWithIdx: KQItem[] = values.map((item) => {
                    const itemPrize = { value: item, idx: indexValue };
                    indexValue++;
                    return itemPrize;
                });
                //filter view option 2,3 number
                if (viewType === '2NUMBER') {
                    kqWithIdx = kqWithIdx.map((item) => ({ ...item, value: item.value.slice(-2) }));
                }
                if (viewType === '3NUMBER') {
                    kqWithIdx = kqWithIdx.map((item) => ({ ...item, value: item.value.slice(-3) }));
                }
                dataLotteryList.push({ title: key, values: kqWithIdx });
            }
        }

        //kiểm tra mm hay mb (leng === 8: mn, length === 9: mn vs mt)
        if (dataLotteryList.length === 8) {
            dataLotteryList = dataLotteryList.map((prize) => {
                const newPrizeValueList = prize.values.map((item: any) => {
                    if (item.idx === 1) {
                        return { ...item, idx: 27 };
                    } else {
                        return { ...item, idx: item.idx - 1 };
                    }
                });
                return { ...prize, values: newPrizeValueList };
            });
        }
        setTableData(dataLotteryList);
    }, [tableDataProps, viewType]);

    return (
        <table className={classNames('tableResultLottery', { southern: tableData.length === 9 }, { northern: tableData.length === 8 })}>
            <tbody>
                {tableData.length > 0 ? (
                    tableData.map((prize: any, index: number) => (
                        <tr key={index}>
                            <td>{t(`${prize.title}`)}</td>
                            <td>
                                <div className={`prize${index}`}>
                                    {prize.values.map((kq: KQItem, idx: number) => (
                                        <div className="numberItem" key={idx}>
                                            {/* kiểm tra là kết quả hay không */}
                                            {kq.value.includes('*') ? (
                                                //kiểm tra giải mở kế tiếp
                                                kq.idx == nextIdxOpenWin ? (
                                                    genrateArrayNumber(kq.value.length).map((value, i) => (
                                                        <NumberRun value={value} key={i} />
                                                    ))
                                                ) : (
                                                    <img className="iconLoading" src={require('assets/images/loading.jpeg')} alt="" />
                                                )
                                            ) : (
                                                <p>{kq.value}</p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={2}>
                            <p className="noResult">Chưa có kết quả</p>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default TableResult;
