import { useEffect, useState } from 'react';

interface Props {
    value: number;
}

let idInterval: any = null;
function NumberRun(props: Props) {
    const { value } = props;
    const [numberValue, setNumberValue] = useState<number>(value);

    useEffect(() => {
        idInterval = setInterval(() => {
            setNumberValue((value) => {
                const newValue = value + 1;
                if (newValue > 9) {
                    return 0;
                }
                return newValue;
            });
        }, 100);
        return () => {
            if (idInterval) clearInterval(idInterval);
        };
    }, []);

    return <span className="numRunItem">{numberValue}</span>;
}

export default NumberRun;
