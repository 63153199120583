import classNames from 'classnames';
import { MenuChanel } from 'models/menuModel';
import { useTranslation } from 'react-i18next';

import './TabChanel.scss';
interface Props {
    menuCountry: MenuChanel;
    chanelType: number;
    onChangeChanel: (value: number) => void;
}
function TabChanel(props: Props) {
    const { menuCountry, chanelType, onChangeChanel } = props;
    const { t } = useTranslation();

    const handleChangeType = (menu: { value: number }) => {
        onChangeChanel(menu.value);
    };
    return (
        <div className="tabChanelContainer">
            <div className="countryImage">
                <img src={menuCountry.icon} alt="" />
            </div>

            {/* list chanel */}
            <ul className="listChanel">
                {menuCountry.subMenu.map((menu, index) => (
                    <li
                        key={index}
                        className={classNames('chanelItem', { active: menu.value == chanelType })}
                        onClick={() => handleChangeType(menu)}
                    >
                        {t(`${menu.name}`)}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default TabChanel;
