import classNames from 'classnames';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { LanguageItem } from 'models/languageModel';
import { MenuChanel } from 'models/menuModel';
import queryString from 'query-string';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { languageList, menuHeader } from 'utils/menu';
import { useTransition, animated } from 'react-spring';
import './DefaultLayout.scss';
import LoadingIcon from 'components/Loading/LoadingIcon';

//set language init
const languageDetect = localStorage.getItem('i18nextLng');
const languageActive = languageList.find((lang) => lang.value === languageDetect) || languageList[0];

interface Props {
    children: JSX.Element;
}
export default function DefaultLayout(props: Props) {
    const { children } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const menuSidebarRef = useRef<HTMLDivElement>(null);

    const queryEvent: any = queryString.parse(location.search);
    const [countrySelected, setCountrySelected] = useState<string>(queryEvent.country ? queryEvent.country : 'vietnam');

    //language
    const [currentLanguage, setCurrentLanguage] = useState<LanguageItem>(languageActive);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const transition = useTransition(showModal, {
        from: { scale: 0.7 },
        enter: { scale: 1 },
    });

    const handleChangeRoute = (routeValue: MenuChanel) => {
        const country = routeValue.name.toLowerCase();
        if (country === 'vietnam') {
            setCountrySelected(country);

            //close menu mobile
            if (menuSidebarRef.current) {
                menuSidebarRef.current.classList.remove('open');
            }
            navigate('/');
        } else {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                setShowModal(true);
            }, 1000);
        }
    };

    const handleChangeLanguage = (lang: LanguageItem) => {
        setCurrentLanguage(lang);
        i18n.changeLanguage(lang.value);
    };

    const handleShowMenuMobile = () => {
        if (menuSidebarRef.current) {
            menuSidebarRef.current.classList.add('open');
        }
    };

    const handleCloseMenuMobile = () => {
        if (menuSidebarRef.current) {
            menuSidebarRef.current.classList.remove('open');
        }
    };

    return (
        <div className="defaultLayout">
            <div className="headerContainer">
                <Header
                    onChangeRoute={handleChangeRoute}
                    countrySelected={countrySelected}
                    language={currentLanguage}
                    onChangeLanguage={handleChangeLanguage}
                    onShowMenuMobile={handleShowMenuMobile}
                />
            </div>
            {/* menu sidebar mobile */}
            <div className="menuSidebarMobile">
                <div className="menuSidebarContainer" ref={menuSidebarRef}>
                    <div className="overlayMenu" onClick={handleCloseMenuMobile} />
                    <div className="menuSidebarList">
                        {menuHeader.map((menu, index) => (
                            <li className="menuItem" key={index}>
                                <p
                                    className={classNames('countryName', { active: menu.name === countrySelected })}
                                    onClick={() => handleChangeRoute(menu)}
                                >
                                    {t(`${menu.name}`)}
                                </p>
                            </li>
                        ))}
                    </div>
                </div>
            </div>
            <div className="defaultContent">{children}</div>
            <div className="footerWrapper">
                <Footer />
            </div>
            {showModal && (
                <div className="popupNoticeContainer">
                    <div className="popupNoticeInner">
                        <div className="popupOverlay" onClick={() => setShowModal(false)} />
                        {transition(
                            (style, item) =>
                                item && (
                                    <animated.div style={style} className="popupContent">
                                        <button type="button" className="btnClose" onClick={() => setShowModal(false)}>
                                            <IoCloseCircleOutline size={32} />
                                        </button>
                                        <img src={require('assets/images/error_icon.png')} alt="" />
                                        <p>{t('notenter')}</p>
                                    </animated.div>
                                )
                        )}
                    </div>
                </div>
            )}
            {loading && <LoadingIcon />}
        </div>
    );
}
