import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

import './BannerSlide.scss';
interface Props {
    bannerList: any[];
}

function BannerSlide(props: Props) {
    const { bannerList } = props;
    return (
        <div className="bannerSliderContainer">
            <Swiper spaceBetween={0} loop autoplay={true} modules={[Pagination, Autoplay]} pagination={{ clickable: true }}>
                {bannerList.map((item, index) => (
                    <SwiperSlide key={index}>
                        <img src={item.image} alt="" className="img-slider" />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default BannerSlide;
