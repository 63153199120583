import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface RegtangleProps {
    height: number | string;
    circle?: boolean;
    width?: number;
}

export default function SkeletonLoading(props: RegtangleProps) {
    const { height, circle, width } = props;
    return <Skeleton height={height} circle={circle} width={width} />;
}
