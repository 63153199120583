import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './languages/en.json';
import vi from './languages/vi.json';
import cn from './languages/cn.json';
export const resources = {
    en: {
        translation: en,
    },
    vi: {
        translation: vi,
    },
    cn: {
        translation: cn,
    },
};

const languageDetect = localStorage.getItem('i18nextLng');

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: languageDetect || 'en',
        ns: ['vi', 'en', 'cn'],
        fallbackLng: 'en',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
