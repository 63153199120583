import { useEffect, useState } from 'react';

interface Props {
    time: string;
    onTimeOut: () => void;
}
function TimeCount(props: Props) {
    const { time, onTimeOut } = props;
    const [timeValue, setTimeValue] = useState<string>('00:00:00');

    useEffect(() => {
        var countDownDate = new Date(time.replace(/ /g, 'T')).getTime();

        // Run myfunc every second
        var myfunc = setInterval(function () {
            var now = new Date().getTime();
            var timeleft = countDownDate - now;

            const day = Math.floor(timeleft / (1000 * 60 * 60 * 24));
            var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
            if (timeleft < 0) {
                onTimeOut();
                clearInterval(myfunc);
            } else {
                setTimeValue(
                    `${('0' + day).slice(-2)}:${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`
                );
            }
        }, 1000);
        return () => {
            clearInterval(myfunc);
        };
    }, [time]);

    return <span className="timeCountContainer">{timeValue}</span>;
}

export default TimeCount;
