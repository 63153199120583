import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'config/i18n';
import App from 'App';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Suspense fallback={<div>loading</div>}>
        <Router>
            <App />
        </Router>
    </Suspense>
);
