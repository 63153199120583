import ReactDOM from 'react-dom';

import styles from './LoadingIcon.module.scss';
function LoadingIcon() {
    return ReactDOM.createPortal(
        <div className={styles['loading-container']}>
            <div className={styles['loader']} />
        </div>,
        document.getElementById('root') as HTMLDivElement
    );
}

export default LoadingIcon;
