export const bannerSlide = [
    {
        image: require('assets/images/banner-1.png'),
    },
    {
        image: require('assets/images/banner-2.jpeg'),
    },
    {
        image: require('assets/images/banner-1.png'),
    },
    {
        image: require('assets/images/banner-2.jpeg'),
    },
];
