import DefailPage from 'pages/Detail/DetailPage';
import HomePage from 'pages/HomePage/HomePage';
import React from 'react';
interface RouteItem {
    path: string;
    page: React.FC;
    value: string;
    layout?: 'DEFAULT' | null;
}
const appRoutes: RouteItem[] = [
    {
        path: '/',
        page: HomePage,
        value: 'Home',
    },
    {
        path: '/detail',
        page: DefailPage,
        value: 'Detail',
    },
];

export default appRoutes;
