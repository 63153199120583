import { bannerSlide } from 'utils/banner';
import BannerSlide from 'components/Banner/BannerSlide';
import TabChanel from 'components/TabChanel/TabChanel';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { menuHeader } from 'utils/menu';
import { MenuChanel } from 'models/menuModel';
import queryString from 'query-string';
import LotteryChanel from 'components/LotteryChanel/LotteryChanel';
import { useTranslation } from 'react-i18next';
import userApi from 'apis/userApi';
import './Homepage.scss';
import SkeletonLoading from 'components/SkeletonLoading/SkeletonLoading';

export default function HomePage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const queryEvent: any = queryString.parse(location.search);

    const [chanelType, setChanelType] = useState<number | null>(null);
    const [countryActive, setCountryActive] = useState<MenuChanel>(menuHeader[0]);
    const [dataLottery, setdataLottery] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //country
        const countryMatch = menuHeader.find((route) => route.name.toLowerCase() === queryEvent.country);
        if (countryMatch) {
            if (countryMatch.name !== countryActive.name) {
                setCountryActive(countryMatch);
            }
        }

        //chanel
        if (queryEvent.type) {
            setChanelType(queryEvent.type);
        } else {
            setChanelType(-1);
        }
    }, [queryEvent]);

    useEffect(() => {
        if (chanelType) {
            setdataLottery([]);
            const getListLottery = async () => {
                setLoading(true);
                const payload = new URLSearchParams({ flag: 'TodayLotteryListBean', type: `${chanelType}`, num: '7' });
                try {
                    const { data, status } = await userApi.getListLottery(payload);
                    if (status === 200) {
                        setdataLottery(data.data);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setLoading(false);
                }
            };
            getListLottery();
        }
    }, [chanelType]);

    const handleChangeChanel = (value: number) => {
        //change params url
        const routeSelect = countryActive?.name;
        navigate({ pathname: '', search: `?country=${routeSelect}&type=${value}` });
    };

    const handleViewDetailLottery = (lotteryid: number) => {
        navigate({ pathname: '/detail', search: `?country=${countryActive.name}&id=${lotteryid}` });
    };

    return (
        <div className="homePageContainer">
            <div className="bannerSlide">
                <BannerSlide bannerList={bannerSlide} />
            </div>
            <div className="bodyLottery">
                {countryActive && chanelType && (
                    <TabChanel menuCountry={countryActive} chanelType={chanelType} onChangeChanel={handleChangeChanel} />
                )}

                {/* lottery list result */}
                <div className="resultLotteryList">
                    {!loading ? (
                        <>
                            {dataLottery.length > 0 ? (
                                dataLottery.map((chanel, index) => (
                                    <div className="lotteryItem" key={index}>
                                        <h6 className="lotteryItemTitle">{t(`${chanel.lotteryid}`)}</h6>

                                        <LotteryChanel
                                            curentIssueProps={chanel.cursitem}
                                            historyList={chanel.history}
                                            onViewDetail={handleViewDetailLottery}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div className="noDataLottery">
                                    <img src={require('assets/images/bingo.png')} alt="" />
                                    <p>{t('noresult')}</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="loadingList">
                            {[1, 2, 3, 4].map((item) => (
                                <div className="loadingItem" key={item}>
                                    <div className="loadingTitle">
                                        <SkeletonLoading height={21} width={150} />
                                    </div>
                                    <div className="loadingTable">
                                        <div className="tableResult">
                                            <SkeletonLoading height={'100%'} />
                                        </div>
                                        <div className="issueList">
                                            <SkeletonLoading height={'100%'} />
                                        </div>
                                        <div className="nextIssue">
                                            <SkeletonLoading height={'100%'} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {/* description page */}
            <div className="descriptionSection">
                <div className="descriptionInner">
                    <h5 className="descTitle">{t('lotterytitle')}</h5>
                    <div className="regionAvailabel">
                        <p className="countryTitle">{t('country')}:</p>
                        <div className="regionList">
                            {menuHeader.map((region) => (
                                <div className="regionItem" key={region.name}>
                                    <img src={region.icon} alt="" />
                                    <p>{region.name.toLowerCase()}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <p className="descContent">{t('lotterydesc')}</p>
                </div>
            </div>
        </div>
    );
}
